<template>
  <div>
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="Ürün Adı"
              v-model="productNew.name"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Ürün Açıklaması"
              v-model="productNew.summary"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Ürün Fiyatı (₺)"
              v-model="productNew.price"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              v-model="productNew.ingredientIds"
              :items="ingredients"
              chips
              deletable-chips
              multiple
              item-text="name"
              item-value="id"
              label="Malzeme Seçimi"
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions class="d-flex justify-end">
        <v-btn color="primary" @click="addProduct" :loading="addProductButtonLoading"
          ><v-icon left>mdi-plus-circle</v-icon>Ürünü Ekle</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "New Product",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      products: [],
    addProductButtonLoading: false,
      ingredients: [],
      productNew: {
        name: "",
        price: null,
        summary: "",
        ingredientIds: [],
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    getIngredients() {
      this.$axios
        .get("/ingredients")
        .then((res) => {
          this.ingredients = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProducts() {
      this.$axios
        .get("/products")
        .then((res) => {
          this.products = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addProduct()
    {
        this.addProductButtonLoading = true;
        this.$axios.post('/products', this.productNew).then((res) => {
            console.log(res);
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            this.addProductButtonLoading = false;
        })
    }
  },
  created() {
    this.getIngredients();
    this.getProducts();
  },
};
</script>
<style lang="" scoped></style>
