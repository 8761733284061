<template>
  <div>
    <v-container fluid> 
    <v-btn color="primary" class="mr-4 mt-4">Siparişler</v-btn>
    <v-btn color="warning" class="mr-4 mt-4">Masa Adisyonları</v-btn>
    <v-btn color="error" class="mr-4 mt-4">Kurye Takip</v-btn>
    <v-btn color="green lighten-3 white--text" class="mr-4 mt-4">Ürünler</v-btn>


    <v-row>
      <v-col cols="12" md="6">
        <ProductNew class="mt-12"></ProductNew>

      </v-col>
    </v-row>

    </v-container>
  </div>
</template>
<script>
import ProductNew from '../../components/Products/ProductNew.vue';
export default {
  name: "",
  components: {ProductNew},
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="" scoped></style>
