import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexComponent from '../views/Index.vue';
import Profile from '../views/Profile.vue'
import Basket from '../views/Basket/Basket.vue'
import Checkout from '../views/Basket/Checkout.vue'

import AdminIndex from '../views/Admin/Index.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: IndexComponent
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/basket',
    name: 'Basket',
    component: Basket
  },
  {
    path: '/basket/checkout',
    name: 'BasketCheckout',
    component: Checkout
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminIndex
  },
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

