<template>
  <div>
   <p class="text-h6 mt-2">Adreslerim</p>
   <v-divider></v-divider>
    <v-container>
      <NewAddress></NewAddress>
      
      <v-row>
        <v-col
          cols="12"
          v-for="(address, index) in addresses"
          :key="'address-' + index"
        >
          <v-card elevation="10">
            <v-card-title> {{ address.fullAddress }}</v-card-title>
          </v-card>
        </v-col>
      </v-row>
      <pre>
        {{addresses}}
      </pre>
    </v-container>
  </div>
</template>
<script>
import NewAddress from '../../components/Shared/NewAddress.vue';
export default {
  name: "",
  components: {
    NewAddress
  },
  mixins: [],
  props: {},
  data() {
    return {
      addresses: [],
      loading: true,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    getAddressess() {
      this.$axios
        .get("/addresses")
        .then((res) => {
          this.addresses = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getAddressess();
    
  },
};
</script>
<style lang="" scoped></style>
