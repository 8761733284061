<template>
  <div>
    <v-chip-group v-model="selectedCategory"
    active-class="primary">
      <v-chip
        class="ma-2"
        v-for="(category, index) in categories"
        :key="'categories' + index"
        :value="category.id"
      >
        <v-avatar left>
          <v-icon>mdi-view-list</v-icon>
        </v-avatar>
        {{ category.name }}
      </v-chip>
    </v-chip-group>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      selectedCategory: null,
      categories: [],
    };
  },
  computed: {},
  watch: {
    selectedCategory(val)
    {
      this.$emit('changeCategory', val);
    }
  },
  mounted() {},
  methods: {
    getCategories() {
      this.$axios.get("/categories").then((res) => {
        this.categories = res.data.data;
      });
    },
  },
  created() {
    this.getCategories();
  },
};
</script>
<style lang="" scoped></style>
