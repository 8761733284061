<template>
  <v-app>
    <v-app-bar app dark>
      <template v-slot:img="{ props }" class="animationImg">
        <v-img
          v-bind="props"
          src="https://i.hizliresim.com/osl7kgy.jpeg"
          class="animationImg"
        ></v-img>
      </template>

      <v-app-bar-title style="cursor: pointer"
        ><v-img
          @click="goToMainPage()"
          src="https://i.hizliresim.com/plskhkm.png"
          width="100"
        ></v-img
      ></v-app-bar-title>

      <v-spacer></v-spacer>
      <BasketIcon></BasketIcon>
      <Login></Login>
    </v-app-bar>

    <v-main>
        <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Login from "./components/Login.vue";
import BasketIcon from "./components/BasketIcon.vue";
export default {
  name: "App",
  components: {
    Login,
    BasketIcon,
  },
  data: () => ({
    //
  }),
  methods: {
    goToMainPage() {
      this.$router.push({ name: "Index" });
    },
  },
};
</script>
<style lang="css">
.v-btn {
  text-transform: none;
}

.v-tab {
  text-transform: none;
}
</style>
