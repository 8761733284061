<template>
  <div>
    <v-container fluid>
      <v-progress-circular
        indeterminate
        color="primary"
        v-if="loadingAddress"
      ></v-progress-circular>
      <div v-if="addresses.length > 0">
        <v-container fluid>
          <v-select v-model="selectedAddressId" label="Adres" item-text="fullAddress" item-value="id" :items="addresses"></v-select>
          <v-btn color="primary">Bu Adresten Devam Et</v-btn>
        </v-container>
      </div>
      <div>
        <div v-if="addresses.length == 0">
          <NewUser></NewUser>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import NewUser from "../../components/Basket/NewUser.vue";

export default {
  name: "",
  components: { NewUser },
  mixins: [],
  props: {},
  data() {
    return {
      addresses: [],
      selectedAddressId: null,
      loadingAddress: true,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    getAddresses() {
      this.$axios
        .get("/addresses")
        .then((res) => {
          this.addresses = res.data.data;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response.status);
        })
        .finally(() => {
          this.loadingAddress = false;
        });
    },
  },
  created() {
    this.getAddresses();
  },
};
</script>
<style lang="" scoped></style>
