<template>
  <v-container fluid>
    <v-dialog :value="false">
      <div class="d-flex justify-center" @click="setSeeBanner()">
        <v-img src="https://i.hizliresim.com/9mkix5f.png"></v-img>
      </div>
    </v-dialog>

    <Products></Products>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Products from "../components/Products/Products.vue";

export default {
  name: "Index",
  components: {
    Products,
  },
  data() {
    return {
    };
  },
  computed:{
    showDialog()
    {
      return !this.$store.state.seeBanner;
    }
  },
  methods:{
    setSeeBanner()
    {
      this.$store.commit('SET_BANNER');
    }
  }
};
</script>
