export const RulesMustBe = {
  data() {
    return {
      RulesMustBe: [(v) => !!v || "*"],
    };
  },
};

export const RulesPassword = {
  data() {
    return {
      RulesPassword: [
        (v) => !!v || "*",
        (v) => String(v).length >= 6 || "En az 6 karakter",
      ],
    };
  },
};

export const RulesEmail = {
  data() {
    return {
      RulesEmail: [(v) => !!v || "*", (v) => /.+@.+/.test(v) || "Hatalı Email"],
    };
  },
};
