<template>
   <div>
     <v-btn :color="getBasketLength > 0 ? 'success': ''" dark @click="goToBasket"><v-icon left>mdi-basket</v-icon>{{ getBasketLength }}</v-btn>
   </div>
</template>
<script>
export default {
   name: '',
   components: {
     
   },
   mixins: [],
   props: {
     
   },
   data() {
     return {
       
     }
   },
   computed: {
     getBasketLength()
     {
        let total = 0;
        total = this.$store.state.basket.reduce((acc, current) => acc + current.quantity, 0);
        return total;
     }
   },
   watch: {
     
   },
   mounted() {
     
   },
   methods: {
    goToBasket()
    {
        this.$router.push({name:'Basket'});
    }
     
   }
};
</script>
<style lang='' scoped>
</style>