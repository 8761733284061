<template>
  <div style="height: 100%" class="d-flex">
    <div>
      <v-navigation-drawer permanent expand-on-hover>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6"> Profilim </v-list-item-title>
            <v-list-item-subtitle>
              Hesap Ayarları ve İşlem Geçmişleri
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav v-model="selectedNavigate">
          <v-list-item-group v-model="selectedNavigate" mandatory>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              link
              :value="item.component"
              :disabled="item?.disabled"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>
    <div style="width: 100%">
      <v-container>
        <component :is="selectedNavigate"></component>
      </v-container>
    </div>
  </div>
</template>
<script>
import Addresses from "./Profile/Addresses";
import Orders from "./Profile/Orders";
import ProfileSettings from "./Profile/ProfileSettings";
import Logout from "./Profile/Logout.vue";
export default {
  name: "Profile",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      selectedNavigate: null,
      items: [
        { title: "Profil", icon: "mdi-account", component: ProfileSettings },
        { title: "Siparişlerim", icon: "mdi-list-box", component: Orders, disabled:true },
        { title: "Adreslerim", icon: "mdi-map-marker", component: Addresses, disabled:true },
        { title: "Çıkış Yap", icon: "mdi-logout", component: Logout },
      ],
      right: null,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="" scoped></style>
