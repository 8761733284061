<template>
  <div>
    <v-form ref="form" v-model="validRegister" lazy-validation>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="user.nameSurname"
            label="İsim Soyisim"
            :rules="RulesMustBe"
            required
          ></v-text-field
        ></v-col>
        <v-col cols="12" md="6">
          <vue-tel-input-vuetify
            @validate="phoneValidated"
            :disabled="true"
            v-model="user.phone"
            placeholder="532 015 05 54"
            :onlyCountries="['TR']"
            label="Telefon Numarası"
            required
            mode="international"
            :rules="[
              ...RulesMustBe,
              phoneValidate || 'Hatalı Telefon Numarası',
            ]"
          ></vue-tel-input-vuetify>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="user.email"
            label="E-mail ( Zorunlu Değil )"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="user.birthday"
            label="Doğum Tarihi ( Zorunlu Değil )"
            type="date"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <div class="d-flex justify-end mt-2">
            <v-btn
              :disabled="!validRegister && !phoneValidate"
              color="success"
              class="mr-4"
              @click="updateProfileRequest"
              :loading="loadingUpdateRequest"
            >
              <v-icon left>mdi-refresh</v-icon>Güncelle
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { RulesMustBe, RulesEmail, RulesPassword } from "../../utils/rules";
export default {
  name: "",
  components: {},
  mixins: [RulesMustBe, RulesEmail, RulesPassword],
  props: {},
  data() {
    return {
      phoneValidate: false,
      validRegister: true,
      loadingUpdateRequest: false,
      user: {
        nameSurname: null,
        birthday: null,
        phone: null,
        email: null,
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    phoneValidated({ isValid }) {
      this.phoneValidate = isValid;
    },
    setUser(user, token) {
      this.$store.commit("SET_USER", { user, token });
    },
    updateProfileRequest() {
      let validated = this.$refs.form.validate();
      if (validated && this.phoneValidate) {
        this.loadingUpdateRequest = true;
        this.$axios
          .put("/profile", this.user)
          .then((res) => {
            this.setUser(res.data.data);
          })
          .finally(() => {
            this.loadingUpdateRequest = false;
          });
      }
    },
  },
  created() {
    console.log(this.$store.state.user);

    this.user = { ...this.$store.state.user };
    this.loadingUpdateRequest = true;
    this.$axios
      .get("/profile")
      .then((res) => {
        this.setUser(res.data.data);
        this.user = res.data.data;
      })
      .finally(() => {
        this.loadingUpdateRequest = false;
      });
  },
};
</script>
<style lang="" scoped></style>
