<template>
  <v-card
    class="mx-auto mt-4 fill-height d-flex flex-column justify-space-between"
    max-width="374"
    elevation="10"
    style="border-radius: 20px"
  >
    <div>
      <v-card-text class="grey lighten-2">
        <v-img src="https://i.hizliresim.com/plskhkm.png"></v-img>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-title class="d-flex justify-space-between"
        ><span style="word-break: break-word">{{
          product.name
        }}</span></v-card-title
      >
      <v-card-subtitle>{{ product.summary }}</v-card-subtitle>

      <v-card-text>
        <v-row align="center" class="mx-0" v-if="product.ratingVisible">
          <v-rating
            :value="4.5"
            color="amber"
            dense
            half-increments
            readonly
            size="14"
          ></v-rating>

          <div class="grey--text ms-4">4.5 (413)</div>
        </v-row>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-subtitle v-if="product.ingredients.length > 0"
        >Malzemeler</v-card-subtitle
      >

      <v-card-text v-if="product.ingredients.length > 0">
        <v-chip-group v-model="selectedIngredient" column multiple active-class="success" >
          <v-chip
            v-for="(ingredient, ingKey) in product.ingredients"
            class="error"
            filter
            multiple
            :value="ingredient.id"
            :key="'pr-ing' + ingKey"
            style="width: 100%"
            >{{ ingredient.name }}</v-chip
          >
        </v-chip-group>
      </v-card-text>
    </div>
    <div>
      <v-text-field
        placeholder="Not"
        outlined
        rounded
        class="mx-4"
        v-model="comment"
      ></v-text-field>
      <v-card-actions class="d-flex justify-space-between mb-4">
        <div class="pl-2 text-h5">{{ product.price }} ₺</div>
        <div>
          <v-btn
            color="deep-purple lighten-2"
            text
            @click="addToBasket"
            v-if="isProductInBasket == 0"
            :disabled="false"
          >
            Sepete Ekle
          </v-btn>
          <div v-if="isProductInBasket != 0">
            <v-btn
              color="deep-purple lighten-2"
              text
              @click="removeToLastIndex"
            >
              <v-icon>mdi-minus-circle</v-icon>
            </v-btn>
            {{ totalQuantity }}
            <v-btn color="deep-purple lighten-2" text @click="addToBasket">
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-actions>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "",
  components: {},
  mixins: [],
  props: ["product"],
  data() {
    return {
      comment: null,
      quantity: 0,
      selectedIngredient: [],
      defaultIngredient: [],
    };
  },
  computed: {
    isProductInBasket() {
      let orderItem =
        this.$store.state.basket.filter(
          (orderI) => orderI.productId == this.product.id
        ) || 0;
      return orderItem || 0;
    },

    totalQuantity() {
      let orderItems = this.isProductInBasket;
      let total = 0;
      if (orderItems != 0) {
        total = orderItems.reduce((acc, current) => acc + current.quantity, 0);
      }

      return total;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    addToBasket() {
      let orderItem = {
        quantity: 1,
        productId: this.product.id,
        comment: this.comment,
        ingredients: this.selectedIngredient
      };
      this.$store.commit("ADD_BASKET", orderItem);
      let text =
        this.comment != null
          ? "Ürününüz " + this.comment + " notuyla sepete eklendi"
          : "Ürün sepete eklendi";
      this.$toast.clear();
      this.$toast.success(text);
      this.comment = null;
      console.log(this.defaultIngredient);
      this.selectedIngredient = JSON.parse(JSON.stringify(this.defaultIngredient));
    },
    removeToLastIndex() {
      let orderItems = this.isProductInBasket;

      let orderItemsLength = this.isProductInBasket.length;

      if (orderItemsLength == 1) {
        let index = this.$store.state.basket.findIndex((orderItem) => {
          return orderItem.productId == orderItems[0].productId;
        });

        if (index > -1) {
          let orderItemUpdate = this.$store.state.basket[index];
          orderItemUpdate.quantity -= 1;

          if (orderItemUpdate.quantity > 0) {
            let payload = {
              index,
              payload: orderItemUpdate,
            };
            this.$store.commit("UPDATE_ORDER_ITEM_BASKET", payload);
            return;
          }
        }
      }
      let lastItem = orderItems.pop();

      let lastIndex = this.$store.state.basket.findLastIndex((orderItem) => {
        return orderItem.productId == lastItem.productId;
      });
      this.$store.commit("REMOVE_BASKET_INDEX", lastIndex);
      if (lastItem.comment == null) {
        this.$toast.clear();
      }
      let text =
        lastItem.comment != null
          ? lastItem.comment + " notu olan ürününüz sepetten kaldırıldı"
          : "Ürün sepetten kaldırıldı";

      this.$toast.warning(text);
    },
  },
  created() {
    if(this.product.name.includes('Pizza'))
    {
      console.log(this.product);
    }
    if (this.product.ingredients != null) {
      this.product.ingredients.map((ingredient) => {
        this.selectedIngredient.push(ingredient.id);
      });

      this.defaultIngredient = JSON.parse(JSON.stringify(this.selectedIngredient));
    }
  },
};
</script>
<style lang="" scoped></style>
