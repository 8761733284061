<template>
   <div>
    <v-container fluid>
        <div class="text-h4 d-flex align-center"><v-icon left class="text-h4" color="success">mdi-basket</v-icon>Sepetim</div>
        <v-divider></v-divider>
        <ProductsBasket></ProductsBasket>
    </v-container>
     
   </div>
</template>
<script>
import ProductsBasket from '../../components/Basket/ProductsBasket.vue';

export default {
   name: '',
   components: {
    ProductsBasket
},
   mixins: [],
   props: {
     
   },
   data() {
     return {
       
     }
   },
   computed: {
     
   },
   watch: {
     
   },
   mounted() {
     
   },
   methods: {
     
   }
};
</script>
<style lang='' scoped>
</style>