<template>
  <v-container fluid>
    <v-dialog v-model="passwordDialog" width="500" max-width="80%">
      <v-card>
        <v-container>
          <v-card-text
            >Sayın {{ newAddress.nameSurname }}, bir sonraki siparişlerinizi daha hızlı oluşturmamız adına adres bilgilerinizi kayıt altına almak istiyoruz. 
            Hesabınızı oluşturabilmek adına aşağıdaki alana lütfen geçerli bir parola giriniz.
            </v-card-text
          >
          <v-card-text>
            <v-text-field
              label="Parola"
              type="password"
              v-model="newAddress.password"
              :rules="RulesPassword"
            ></v-text-field>
          </v-card-text>
          <v-card-text
            ><v-btn
              @click="registerWithOrderSend"
              color="success"
              :disabled="!String(newAddress.password).length >= 6"
              >Kaydet</v-btn
            ></v-card-text
          >
        </v-container>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-text>Yeni Adres</v-card-text>
      <v-card-text>
        <v-form ref="form" v-model="validNewAddress" lazy-validation>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="İsim Soyisim"
                v-model="newAddress.nameSurname"
                :rules="RulesMustBe"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Adres"
                v-model="newAddress.fullAddress"
                :rules="RulesMustBe"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Açık Adres ( Zorunlu Değil )"
                v-model="newAddress.openAddress"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <vue-tel-input-vuetify
                @validate="phoneValidated"
                v-model="newAddress.phone"
                placeholder="532 015 05 54"
                :onlyCountries="['TR']"
                label="Telefon Numarası"
                required
                mode="international"
                :rules="[
                  ...RulesMustBe,
                  phoneValidate || 'Hatalı Telefon Numarası',
                ]"
              ></vue-tel-input-vuetify>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Apartman ( Zorunlu Değil )"
                v-model="newAddress.apartment"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Kat ( Zorunlu Değil )"
                v-model="newAddress.floor"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Daire ( Zorunlu Değil )"
                v-model="newAddress.flat"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Şirket ( Zorunlu Değil )"
                v-model="newAddress.company"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Not ( Zorunlu Değil )"
                v-model="newAddress.notes"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn color="success" @click="registerWithOrder"
                ><v-icon>mdi-check-circle</v-icon>Siparişi Tamamla</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { RulesMustBe, RulesPassword } from "../../utils/rules";

export default {
  name: "",
  components: {},
  mixins: [RulesMustBe, RulesPassword],
  props: {},
  data() {
    return {
      validNewAddress: false,
      passwordDialog: false,
      newAddress: {
        nameSurname: null,
        password: null,
        phone: null,
        fullAddress: null,
        apartment: null,
        flat: null,
        floor: null,
        openAddress: null,
        company: null,
        notes: null,
        tag: null,
      },
      phoneValidate: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    phoneValidated({ isValid }) {
      this.phoneValidate = isValid;
    },
    registerWithOrderSend() {
      let orderItems = this.$store.state.basket;
      let address = this.newAddress;
      this.$axios
        .post("/register-with-order", { orderItems, address })
        .then((res) => {
          console.log(res);
          let user = res.data.data.user;
          let token = res.data.data.token;
          this.$store.commit("SET_USER", { user, token });
          this.$toast.success('Siparişiniz başarıyla oluştu');
          this.passwordDialog = false;
          this.$store.commit('CLEAR_BASKET');
          this.$router.push({name:'Profile'});
        })
        .catch((err) => {
          console.log(err);
          let message = err?.response?.data?.message || 'Beklenmedik bir hata oluştu';
          this.$toast.error(message);
          this.passwordDialog = false;
        });
    },
    registerWithOrder() {
      let validated = this.$refs.form.validate();

      if (validated && this.phoneValidate) {
        let orderItems = this.$store.state.basket;
        let address = this.newAddress;

        if (this.$store.state.token != null) {
          this.$axios
            .post("/orders", { orderItems, address })
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              let message = err.response.data.message;
              this.$toast.warning(message);
            });
        } else {
          this.passwordDialog = true;
        }
      }
    },
  },
};
</script>
<style lang="" scoped></style>
