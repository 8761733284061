import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    user: null,
    basket: [],
    seeBanner: false,
  },
  mutations: {
    SET_BANNER(state) {
      state.seeBanner = true;
    },
    SET_USER(state, { user, token }) {
      if (token != null) {
        state.token = token;
      }
      console.log("user", user);
      state.user = user;
    },
    ADD_BASKET(state, payload) {
      let currentOrderItem = state.basket.find(
        (orderItem) =>
          orderItem.productId == payload.productId &&
          orderItem.comment == payload.comment &&
          JSON.stringify(orderItem.ingredients) ==
            JSON.stringify(payload.ingredients)
      );
      if (!currentOrderItem) {
        return state.basket.push(payload);
      }
      currentOrderItem.quantity += payload.quantity;
    },
    REMOVE_BASKET_INDEX(state, index) {
      state.basket.splice(index, 1);
    },
    UPDATE_ORDER_ITEM_BASKET(state, { index, payload }) {
      state.basket.splice(index, 1, payload);
    },
    CLEAR_BASKET(state) {
      state.basket = [];
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
