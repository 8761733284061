import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";

import axiosIns from "./axios";
import helper from "./utils/helper";

import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);

Vue.prototype.$helper = helper;

Vue.prototype.$axios = axiosIns;

Vue.use(VueTelInputVuetify, { vuetify });

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  position: POSITION.BOTTOM_RIGHT,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
