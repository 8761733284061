<template>
  <div>
    <v-card>
      <v-card-text>Yeni Adres</v-card-text>
      <v-card-text>
        <v-form ref="form" v-model="validNewAddress" lazy-validation>
          <v-row>
            <v-col cols="12">
              <l-map
                ref="myMap"
                @ready="myMapIsReady()"
                style="height: 300px; width: 100%"
                :center="mapSettings.center"
                :zoom="mapSettings.zoom"
              >
                <l-tile-layer
                  :url="mapSettings.url"
                  :attribution="mapSettings.attribution"
                ></l-tile-layer>
              </l-map>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="İsim Soyisim"
                v-model="newAddress.nameSurname"
                :rules="RulesMustBe"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Adres"
                v-model="newAddress.fullAddress"
                :rules="RulesMustBe"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Açık Adres ( Zorunlu Değil )"
                v-model="newAddress.openAddress"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <vue-tel-input-vuetify
                @validate="phoneValidated"
                v-model="newAddress.phone"
                placeholder="532 015 05 54"
                :onlyCountries="['TR']"
                label="Telefon Numarası"
                required
                mode="international"
                :rules="[
                  ...RulesMustBe,
                  phoneValidate || 'Hatalı Telefon Numarası',
                ]"
              ></vue-tel-input-vuetify>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Apartman ( Zorunlu Değil )"
                v-model="newAddress.apartment"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Kat ( Zorunlu Değil )"
                v-model="newAddress.floor"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Daire ( Zorunlu Değil )"
                v-model="newAddress.flat"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Şirket ( Zorunlu Değil )"
                v-model="newAddress.company"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Not ( Zorunlu Değil )"
                v-model="newAddress.notes"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { RulesMustBe } from "../../utils/rules";
export default {
  name: "",
  components: {},
  mixins: [RulesMustBe],
  props: {},
  data() {
    return {
      validNewAddress: false,
      phoneValidate: false,

      mapSettings: {
        center: [40.74744, 30.32901],
        zoom: 15,
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      },
      newAddress: {
        nameSurname: null,
        password: null,
        phone: null,
        fullAddress: null,
        apartment: null,
        flat: null,
        floor: null,
        openAddress: null,
        company: null,
        notes: null,
        tag: null,
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    phoneValidated({ isValid }) {
      this.phoneValidate = isValid;
    },
    myMapIsReady() {
      this.map = this.$refs.myMap.mabObject;
    },
  },
};
</script>
<style lang="" scoped></style>
