<template>
  <div>
    <div v-if="loading == false && products.length > 0 && basket.length">
      <v-card
        height="40"
        width="100%"
        color="secondary"
        class="d-flex align-center justify-space-between px-4 white--text mt-6"
      >
        <span class="text-h6">Sipariş Tutarınız: {{ totalPrice }} ₺ </span>
      </v-card>
      <v-card
        height="40"
        width="100%"
        color="secondary"
        class="d-flex align-center justify-space-between px-4 white--text mt-2"
      >
        <span class="text-h6">Paket Servis Ücreti: 10 ₺ </span>
      </v-card>
      <v-divider class="mt-4 mb-4"></v-divider>
      <v-card
        height="50"
        width="100%"
        color="success"
        class="d-flex align-center justify-space-between px-4 white--text mt-2"
      >
        <span class="text-h6">Toplam: {{ totalPrice + 10 }} ₺ </span>
        <div>
          <v-btn color="primary" @click="goToCheckout()">
            Siparişi Tamamla <v-icon right>mdi-arrow-right</v-icon></v-btn
          >
        </div>
      </v-card>
    </div>
    <v-row v-if="loading">
      <v-col
        cols="12"
        lg="3"
        md="4"
        v-for="(n, index) in basket"
        :key="n + 'loading-basket' + index"
      >
        <v-skeleton-loader
          elevation="10"
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-card
      v-if="basket.length == 0"
      height="50"
      class="mt-6 d-flex align-center px-4"
      color="error"
      dark
      ><v-icon left dark>mdi-alert</v-icon> Sepetiniz Boş
    </v-card>

    <div v-if="loading == false && products.length > 0 && basket.length">
      <v-row>
        <v-col
          cols="12"
          lg="3"
          md="4"
          v-for="(orderItem, index) in basket"
          :key="'od-' + index"
        >
          <v-card
            class="mx-auto mt-4 fill-height d-flex flex-column justify-space-between"
            max-width="374"
            elevation="10"
          >
            <v-img src="https://i.hizliresim.com/plskhkm.png"></v-img>
            <v-divider></v-divider>
            <v-card-title class="d-flex justify-space-between"
              ><span>{{ orderItem.product.name }}</span
              ><span>{{ orderItem.product.price }} ₺</span></v-card-title
            >
            <v-card-subtitle>{{ orderItem.product.summary }}</v-card-subtitle>

            <v-card-text>
              <v-row
                align="center"
                class="mx-0"
                v-if="orderItem.product.ratingVisible"
              >
                <v-rating
                  :value="4.5"
                  color="amber"
                  dense
                  half-increments
                  readonly
                  size="14"
                ></v-rating>

                <div class="grey--text ms-4">4.5 (413)</div>
              </v-row>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-subtitle v-if="orderItem.product.ingredients != null"
              >Malzemeler</v-card-subtitle
            >

            <v-card-text v-if="orderItem.product.ingredients != null">
              <v-chip-group
                :disabled="true"
                column
                multiple
                v-model="orderItem.ingredients"
                active-class="success"
              >
                <v-chip
                  filter
                  multiple
                  v-for="(ingredient, ingKey) in orderItem.product.ingredients"
                  :value="ingredient.id"
                  :key="'pr-ing-' + ingKey + 'index-' + index"
                  :disabled="true"
                  style="width: 100%"
                  class="error"
                  >{{ ingredient.name }}</v-chip
                >
              </v-chip-group>
            </v-card-text>
            <v-text-field
              v-model="orderItem.comment"
              @input="orderItemCommentChange(orderItem.comment, index)"
              placeholder="Not Ekle"
              label="Not"
              outlined
              rounded
              class="mx-4"
            ></v-text-field>

            <v-card-actions class="d-flex justify-end">
              <div v-if="orderItem.quantity != 0">
                <v-btn
                  color="deep-purple lighten-2"
                  text
                  @click="removeQuantity(index)"
                >
                  <v-icon>mdi-minus-circle</v-icon>
                </v-btn>
                {{ orderItem.quantity }}
                <v-btn
                  color="deep-purple lighten-2"
                  text
                  @click="addQuantity(index)"
                >
                  <v-icon>mdi-plus-circle</v-icon>
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  mixins: [],
  props: ["product"],
  data() {
    return {
      loading: true,
      products: [],
      basket: [],
    };
  },
  computed: {
    totalPrice() {
      let total = 0;
      this.basket.map((orderItem) => {
        total += orderItem.quantity * orderItem.product.price;
      });

      return total;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    goToCheckout() {
      let whatsappText = "";

      this.basket.map((bp) => {
        let globalProduct = this.products.find(
          (prod) => prod.id == bp.product.id
        );

        let uncheckIngredients = globalProduct.ingredients.filter(
          (ing) => !bp.ingredients.includes(ing.id)
        );
        console.log(uncheckIngredients);

        whatsappText += bp.quantity + " - " + bp.product.name + "\n";

        if (uncheckIngredients.length > 0) {
          whatsappText += "İstemediğim Malzemeler: \n";
          uncheckIngredients.map((ing) => {
            whatsappText += ing.name + "\n";
          });
        }

        if (bp.comment != null) {
          whatsappText += "Not: " + bp.comment + "\n";
        }
        whatsappText += "---\n";
      });

      let whatsappTextSending = encodeURIComponent(whatsappText);

      window.open(
        "https://wa.me/905320150554?text=" + whatsappTextSending,
        "_blank"
      );

      console.log(whatsappText);
      // this.$router.push({name:'BasketCheckout'});
    },
    orderItemCommentChange(comment, index) {
      if (comment == "") {
        comment = null;
      }

      let payload = {
        index: index,
        payload: JSON.parse(JSON.stringify(this.basket[index])),
      };

      this.$store.commit("UPDATE_ORDER_ITEM_BASKET", payload);
    },
    addQuantity(index) {
      let item = this.basket[index];
      item.quantity += 1;
      let payload = {
        index,
        payload: item,
      };
      this.$store.commit("UPDATE_ORDER_ITEM_BASKET", payload);
    },
    removeQuantity(index) {
      let item = this.basket[index];
      item.quantity -= 1;

      if (item.quantity <= 0) {
        this.$store.commit("REMOVE_BASKET_INDEX", index);
      } else {
        let payload = {
          index,
          payload: item,
        };
        this.$store.commit("UPDATE_ORDER_ITEM_BASKET", payload);
      }
    },
  },
  created() {
    this.basket = this.$store.state.basket;
    this.$axios
      .get("/products")
      .then((res) => {
        this.products = res.data.data;
        this.basket.map((orderItem) => {
          orderItem.product = this.products.find(
            (prod) => prod.id == orderItem.productId
          );
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>
<style lang="" scoped></style>
