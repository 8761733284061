import axios from "axios";
import store from "../store";

const axiosInstance = axios.create({
  baseURL: "https://apiv1.diverstreetfood.com",
  withCredentials: false,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = Object.assign(
      {
        Authorization: `Bearer ${store.state.token}`,
      },
      config.headers
    );

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
