import store from '../store';

const helper = {
    getRolesArray()
    {
        let rolesArray = store.state?.user?.roles || [];
        rolesArray = rolesArray.map((role) => role.name);
        return rolesArray;
    }
};

export default helper;