<template>
  <div>
    <v-btn text @click="openDialogOrRedirectProfile">
      <v-icon>mdi-account</v-icon>
    </v-btn>

    <v-dialog
      transition="dialog-top-transition"
      v-model="dialog"
      max-width="80%"
      width="600"
    >
      <v-card :height="tab == 0 ? 400 : 'auto'">
        <v-card-title>
          <v-tabs v-model="tab">
            <v-tab><v-icon left>mdi-account</v-icon>Giriş</v-tab>
            <v-tab><v-icon left>mdi-account-plus</v-icon>Kayıt Ol</v-tab>
          </v-tabs>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <vue-tel-input-vuetify
                  @validate="phoneValidated"
                  v-model="phone"
                  :rules="[
                    ...RulesMustBe,
                    phoneValidate || 'Hatalı Telefon Numarası',
                  ]"
                  :onlyCountries="['TR']"
                  label="Telefon Numarası"
                  placeholder="5xx"
                  required
                  mode="international"
                  @keydown.enter="$refs.inputPassword.focus()"
                ></vue-tel-input-vuetify>
                <v-text-field
                  label="Parola"
                  type="password"
                  :rules="RulesPassword"
                  v-model="password"
                  ref="inputPassword"
                  prepend-inner-icon="mdi-lock-outline"
                  @keydown.enter="loginRequest()"
                ></v-text-field>
                <div class="d-flex justify-end">
                  <v-btn
                    rounded
                    color="primary"
                    :disabled="!(phoneValidate && String(password).length >= 6)"
                    :loading="loginLoading"
                    @click="loginRequest"
                    >Giriş Yap</v-btn
                  >
                </div>
              </v-tab-item>
              <v-tab-item>
                <v-form ref="form" v-model="validRegister" lazy-validation>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="register.nameSurname"
                        label="İsim Soyisim"
                        :rules="RulesMustBe"
                        required
                      ></v-text-field
                    ></v-col>
                    <v-col cols="12" md="6">
                      <vue-tel-input-vuetify
                        @validate="phoneValidated"
                        v-model="register.phone"
                        placeholder="532 015 05 54"
                        :onlyCountries="['TR']"
                        label="Telefon Numarası"
                        required
                        mode="international"
                        :rules="[
                          ...RulesMustBe,
                          phoneValidate || 'Hatalı Telefon Numarası',
                        ]"
                      ></vue-tel-input-vuetify>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="register.email"
                        label="E-mail ( Zorunlu Değil )"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="register.birthday"
                        label="Doğum Tarihi ( Zorunlu Değil )"
                        type="date"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="register.password"
                        type="password"
                        :rules="RulesPassword"
                        label="Parola"
                        placeholder="****"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <div class="d-flex justify-end mt-2">
                        <v-btn
                          :disabled="!validRegister && !phoneValidate"
                          color="success"
                          class="mr-4"
                          @click="registerRequest"
                          :loading="registerLoading"
                        >
                          Kayıt Ol
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { RulesMustBe, RulesEmail, RulesPassword } from "../utils/rules";
export default {
  name: "",
  components: {},
  mixins: [RulesMustBe, RulesEmail, RulesPassword],
  props: {},
  data() {
    return {
      tab: "login",
      registerLoading: false,
      loginLoading: false,
      validRegister: true,
      phoneValidate: false,
      dialog: false,
      phone: null,
      password: null,
      register: {
        name: null,
        password: null,
        birthday: null,
        phone: null,
        email: null,
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    setUser(user, token) {
      this.$store.commit("SET_USER", { user, token });
    },
    phoneValidated({ isValid }) {
      this.phoneValidate = isValid;
    },
    registerRequest() {
      let validated = this.$refs.form.validate();
      if (validated && this.phoneValidate) {
        this.registerLoading = true;
        this.$axios
          .post("/register", this.register)
          .then((res) => {
            this.setUser(res.data.user, res.data.token);
          })
          .finally(() => {
            this.registerLoading = false;
          });
      }
    },
    loginRequest() {
      this.loginLoading = true;

      this.$axios
        .post("/login", { phone: this.phone, password: this.password })
        .then((res) => {
          this.setUser(res.data.data.user, res.data.data.token);
          this.dialog = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loginLoading = false;
        });
    },
    openDialogOrRedirectProfile() {
      if (this.$store.state?.user != null && this.$store.state?.token != null) {
        return this.$router.push({ name: "Profile" });
      }

      this.dialog = true;
    },
  },
};
</script>
<style lang=""></style>
