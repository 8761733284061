<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card color="success"
          ><v-card-text
          class="white--text"
            ><v-icon left color="white">mdi-basket</v-icon> Siparişinize sitemiz üzerinden devam edebilirsiniz
            </v-card-text
          ></v-card
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <Categories @changeCategory="filteredByCategory"></Categories>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12" lg="3" md="4" v-for="n in 4" :key="n">
        <v-skeleton-loader
          elevation="10"
          class="mx-auto"
          max-width="300"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="!loading && products.length == 0">
      <v-col cols="12">
        <v-card>
          Üzgünüz, şuan için listeleyebileceğimiz bir ürünümüz yok.
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!loading && products.length >= 1">
      <v-col
        cols="12"
        lg="3"
        md="4"
        v-for="(product) in filteredProducts"
        :key="'product-' + product.id"
      >
        <Product :product="product" :key="product.id + '--id'" ></Product>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Product from "./Product.vue";
import Categories from "../Categories.vue";
export default {
  name: "",
  components: { Product, Categories },
  mixins: [],
  props: {},
  data() {
    return {
      products: [],
      filteredProducts: [],
      loading: true,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    filteredByCategory(categoryId = null) {
      if (categoryId == null) {
        this.filteredProducts = this.products;
        return;
      }

      this.filteredProducts = this.products.filter((product) => {
       return product.categoryId == categoryId;
      });
      
    },
    getProducts() {
      this.loading = true;
      this.$axios
        .get("/products")
        .then((res) => {
          this.products = res.data.data;
          this.filteredProducts = this.products;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getProducts();
  },
};
</script>
<style lang="" scoped></style>
