<template>
  <div>
    <v-btn color="error" @click="logout"
      ><v-icon>mdi-logout</v-icon>Çıkış Yap</v-btn
    >
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    logout() {
      let payload = {
        user: null,
        token: null,
      };
      this.$store.commit("SET_USER", payload);
      this.$router.push({name:'Index'});
    },
  },
};
</script>
<style lang="" scoped></style>
